// Set file variable
$filename: 'scss/custom/_buttons.scss';

/*==============================================================================
  @Buttons - #{$filename}
==============================================================================*/

.button {
  @include make-button();
  @include add-button-color((
    'background-deg' : 2%,
  ));
  // There are cases where we want button text to wrap
  white-space: normal;

  &.block {
    display: block;
  }
}
.button.default {
  @include add-button-color((
    'background' : rgba($black, 0.05),
    'hover' : (
      'background' : rgba($black, 0.1)
    )
  ));
}
.button.primary {
  color: $white;
  background: $accent-darker;
  background-image: linear-gradient(to bottom, $accent, $accent-darker);
  background-repeat: repeat-x;
  transition: background-position map-get($buttons, 'transition-duration') map-fetch($buttons, 'hover', 'transition-timing-function');
  &:hover {
    color: $white;
    background-position: 0 -40px;
    transition: background-position map-fetch($buttons, 'hover', 'transition-duration') map-fetch($buttons, 'hover', 'transition-timing-function');
  }
}

.button.outline {
  @include add-button-color((
    'background' : transparent,
    'border' : 1px solid rgba($black, 0.1),

    'hover' : (
      'background' : transparent,
      'border-color' : $accent,
    ),

    'active' : (
      'background' : transparent,
      'border-color' : $accent,
    ),
  ));
}
