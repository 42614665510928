// Set file variable
$filename: 'scss/custom/_header.scss';

/*==============================================================================
  @Header - #{$filename}
==============================================================================*/

.header {
  background: $bg-header;
  padding: 1em 1em 0 1em;
  text-align: center;
  overflow: auto;
}

@include media-min('medium') {

  .header {
    @include remove-clearfix();
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em;
  }

}
